import Rollbar from 'rollbar';
import packageInfo from '../../../package.json';

export const rollbar = new Rollbar({
    accessToken: process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: process.env.NEXT_PUBLIC_ENVIRONMENT !== 'local' || false,
    payload: {
        environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
        client: {
            javascript: {
                // -- Add this into your configuration ---
                code_version: packageInfo.version,
                // ---------------------------------------

                // Optionally have Rollbar guess which frames the error was
                // thrown from when the browser does not provide line
                // and column numbers.
                guess_uncaught_frames: true
            }
        }
    }
});

export function sendToRollbar(message: string, body: any) {
    rollbar.error([`[FRONT] ${message}`, translateBody(body)]);
}

interface LooseObject {
    [key: string]: any;
}
export function translateBody(body: any): any {
    let toSend: LooseObject = {};

    if (body instanceof Error) {
        toSend = translateError(body);
    } else {
        const objectKeys = Object.keys(body);
        for (const key of objectKeys) {
            if (body[key] instanceof Error) {
                toSend[key] = translateError(body[key]);
            } else {
                toSend[key] = body[key];
            }
        }
    }
    return JSON.stringify(toSend);
}

function translateError(error: Error) {
    return {
        name: error.name,
        message: error.message,
        stack: error.stack
    };
}
