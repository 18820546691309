import { fetchEventSource } from '@microsoft/fetch-event-source';
import { Document } from '@langchain/core/documents';

import { Message, Chat } from '@prisma/client';

import { PaginatedData } from '@/types/paginatedData';

import { BaseResponse, HttpRequest } from './fetch-methods';
import generateUrl from './utils/url-generator';
import { sendToRollbar } from '../utils/rollbar';
import { BaseService } from './base-service';
import { UpdateChat } from '@/services/model/chatService';

export type QuestionInfo = {
    client: string;
    from: string;
    question: string;
    history: [string, string][];
    file?: File;
};

export type QuestionAnswer = {
    data: string;
    sourceDocs: Document[];
    filter: Record<string, any>;
};

export interface ChatService {
    getChat(chatId: string): Promise<BaseResponse<Chat>>;
    getMessagesFromChat(
        chatId: string,
        pageable?: Pageable
    ): Promise<BaseResponse<PaginatedData<Message>>>;
    getChats(criteria?: {
        page?: number;
        pageSize?: number;
        input?: string;
        clientId?: string;
        timestamp?: number;
        interactiveChatsOnly?: boolean;
    }): Promise<BaseResponse<PaginatedData<Chat>>>;
    getChatsStatistics(): Promise<BaseResponse<any>>;
    countInteractiveChats(clientId: string): Promise<BaseResponse<Number>>;
    sendQuestion(
        questionInfo: QuestionInfo,
        onerror?: (err: any) => number | void | null | undefined,
        onmessage?: (data: QuestionAnswer) => void,
        ondone?: () => void,
        onHumanMessage?: () => void
    ): Promise<void>;
    updateChat(chatId: string, body: UpdateChat): Promise<BaseResponse<Chat>>;
}

export class ChatServiceImp extends BaseService implements ChatService {
    private httpRequest: HttpRequest;

    constructor(httpRequest: HttpRequest) {
        super('Chat-Service');
        this.httpRequest = httpRequest;
    }

    countInteractiveChats(clientId: string): Promise<BaseResponse<any>> {
        const request = () =>
            this.httpRequest.get<Chat>({
                url: generateUrl(`api/chat/interactive/count`, { clientId })
            });

        return this.tryRequest<Chat>(request);
    }

    updateChat(chatId: string, body: UpdateChat): Promise<BaseResponse<Chat>> {
        const request = () =>
            this.httpRequest.patch<Chat>({
                url: generateUrl(`api/chat/${chatId}`),
                body
            });

        return this.tryRequest<Chat>(request);
    }

    getChat(chatId: string): Promise<BaseResponse<Chat>> {
        const request = () =>
            this.httpRequest.get<Chat>({
                url: generateUrl(`api/chat/${chatId}`)
            });
        return this.tryRequest<Chat>(request);
    }

    getMessagesFromChat(
        chatId: string,
        pageable: Pageable = {
            page: 1,
            pageSize: 20,
            sortBy: 'createdAt',
            sortOrder: 'desc'
        }
    ): Promise<BaseResponse<PaginatedData<Message>>> {
        const request = () =>
            this.httpRequest.get<PaginatedData<Message>>({
                url: generateUrl(`api/message`, { chatId, ...pageable })
            });
        return this.tryRequest<PaginatedData<Message>>(request);
    }

    getChats(criteria?: {
        page?: number;
        pageSize?: number;
        input?: string;
        clientId?: string;
        timestamp?: number;
    }): Promise<BaseResponse<PaginatedData<Chat>>> {
        const request = () =>
            this.httpRequest.get<PaginatedData<Chat>>({
                url: generateUrl(`api/chat`, criteria)
            });
        return this.tryRequest<PaginatedData<Chat>>(request);
    }

    getChatsStatistics(): Promise<BaseResponse<PaginatedData<Chat>>> {
        const request = () =>
            this.httpRequest.get<PaginatedData<Chat>>({
                url: generateUrl('api/chat/statistics')
            });
        return this.tryRequest<PaginatedData<Chat>>(request);
    }

    async sendQuestion(
        questionInfo: QuestionInfo,
        onerror: (err: any) => number | void | null | undefined = () => {},
        onmessage: (data: QuestionAnswer) => void = () => {},
        ondone: () => void,
        onHumanMessage: () => void
    ) {
        const form = new FormData();
        form.append('question', questionInfo.question);
        form.append('client', questionInfo.client);
        form.append('from', questionInfo.from);
        form.append('history', JSON.stringify(questionInfo.history));
        if (questionInfo.file) form.append('file', questionInfo.file);

        const ctrl = new AbortController();

        fetchEventSource('/api/webchat/message', {
            method: 'POST',
            openWhenHidden: true,
            body: form,
            signal: ctrl.signal,
            onerror: (error) => {
                console.error(error);
                onerror(error);
                ctrl.abort();
                throw error;
            },
            onmessage: (event) => {
                if (event.data === '[IGNORED]') {
                    //nothing to do. The message was ignored.
                } else if (event.data === '[HUMAN]') {
                    onHumanMessage();
                    ctrl.abort();
                } else if (event.data === '[DONE]') {
                    ondone();
                    ctrl.abort();
                } else {
                    let data: QuestionAnswer;
                    try {
                        data = JSON.parse(event.data);
                        if ((!data.data || data.data == '') && !data.sourceDocs && !data.filter)
                            return;
                    } catch (e) {
                        console.error(e);
                        sendToRollbar('onmessage error: ', e);

                        return;
                    }
                    onmessage(data);
                }
            }
        });
    }
}
