import { Namespace } from '@prisma/client';

import { BaseResponse, HttpRequest } from './fetch-methods';
import generateUrl from './utils/url-generator';
import { sendToRollbar } from '../utils/rollbar';
import { BaseService } from './base-service';

export interface NamespaceService {
    getNamespace(clientId: string): Promise<BaseResponse<Namespace>>;
}

export class NamespaceServiceImp extends BaseService implements NamespaceService {
    private httpRequest: HttpRequest;

    constructor(httpRequest: HttpRequest) {
        super('Namespace-Service');
        this.httpRequest = httpRequest;
    }

    async getNamespace(clientId: string): Promise<BaseResponse<Namespace>> {
        return this.tryRequest(() =>
            this.httpRequest.get<Namespace>({
                url: generateUrl(`api/client/namespace`, { clientId })
            })
        );
    }
}
